import * as React from 'react'

//import {Link} from 'gatsby'
import Layout from '../components/layout/layout'
//import {StaticImage} from 'gatsby-plugin-image'

const pageTitle = "Privacy Policy"

const PrivacyPage = ({  pageTitle }) => {
    return (
        <Layout pageTitle={pageTitle}>

            <div className="span-23 last" style={{ overflow: "hidden", marginBottom: "60px" }}>
                <br />
                    <p className="blackpurple"><b>OUR PRIVATE POLICY</b></p>

                    <p>At IMA, we are committed to protecting your personal &amp; data privacy. This Privacy Policy aims
                        at informing you what information we collect and how we use it.</p>

                    <br />

                        <b><i>The Personal Data We Collect and Use</i></b>

                        <p>We collect personal data such as your name, telephone number, email, mailing address,
                            education qualification and years of work experience. </p>

                        <p>We use personal data for the purpose of providing a broad range of education and training
                            programs, activities, awards, competitions and other services to our students, customers and
                            inquirers. </p>

                        <br />

                            <b><i>From Whom We Collect and Disclose it </i></b>

                            <p>We may collect personal data from the following sources:
                            </p>
                            <ul>
                                <li> Information we receive from applications or other forms to us;</li>
                                <li> Information about your participation in our activities, with our affiliates, or
                                    others; and
                                </li>
                                <li> Information we receive from non-affiliated third parties, including but not limited
                                    to consumer reporting agencies.
                                </li>
                            </ul>
                            <p></p>

                            <p>In addition, we may jointly use personal data with our subsidiaries, associated
                                companies, universities, academic institutions and student alumni, and other persons who
                                we consider appropriate, in connection with the services and products provided to or
                                requested by you. </p>

                            <br />

                                <b><i>How to Access or Correct Your Personal Data </i></b>

                                <p>You may request access to and correct your personal data held by us. If you wish to
                                    obtain a copy of your personal data; if you believe that the personal data relating
                                    to you which we collect and maintain is incorrect; or if you wish us to remove your
                                    personal data from our record, please write to us: information@ima.hk </p>

                                <br />


                                    <b><i>Our Commitment to Personal Data Security </i></b>

                                    <p> At IMA, we have implemented appropriate physical, technical, and administrative
                                        measures to safeguard and secure the personal data we collect and to prevent
                                        unauthorised access. </p>
            </div>

        </Layout>
)
}

export default PrivacyPage;
